import _ from "lodash";
import mock from "src/utils/mock";
import moment from "moment";

const LICENSES_USED_MAX_VALUE = 70;
const USAGE_LEVEL_MAX_VALUE = 100;

const epoch = {
  year: 1970,
  month: 0,
  date: 1
};

mock.onPost("/api/admin/usage/chart").reply(request => {
  try {
    console.log(JSON.stringify(JSON.parse(request.data).filters));
    const { filters } = JSON.parse(request.data);

    let dataPoints = [];

    let rangeEnd = moment();
    if (filters.range === "custom") {
      rangeEnd = moment(filters.customRange.end);
    }

    let rangeStart;
    switch (filters.range) {
      case "today":
        rangeStart = moment(rangeStart).subtract(1, "days");
        break;
      case "lastWeek":
        rangeStart = moment(rangeStart).subtract(1, "weeks");
        break;
      case "lastMonth":
        rangeStart = moment(rangeStart).subtract(1, "months");
        break;
      case "lastTwoMonths":
        rangeStart = moment(rangeStart).subtract(2, "months");
        break;
      case "lastThreeMonths":
        rangeStart = moment(rangeStart).subtract(3, "months");
        break;
      case "lastSixMonths":
        rangeStart = moment(rangeStart).subtract(6, "months");
        break;
      case "lastYear":
        rangeStart = moment(rangeStart).subtract(1, "years");
        break;
      case "custom":
        rangeStart = moment(filters.customRange.start);
        break;
      case "lastWeek":
      default:
        rangeStart = moment(rangeStart).subtract(1, "weeks");
        break;
    }

    let dataPointsToGenerate;
    if (filters.timeSegmentation === "hourly") {
      dataPointsToGenerate = rangeEnd.diff(rangeStart, "hours");
    } else {
      dataPointsToGenerate = rangeEnd.diff(rangeStart, "days");
    }

    let dataPointTime = rangeStart;
    for (let i = 0; i < dataPointsToGenerate; i++) {
      if (filters.timeSegmentation === "hourly") {
        dataPointTime.add(1, "hours");
      } else {
        dataPointTime.add(1, "days");
      }

      const dayOfWeek = dataPointTime.day();
      if (
        filters.range === "custom" &&
        filters.customRange.excludeWeekends &&
        (dayOfWeek === 0 || dayOfWeek === 6)
      ) {
        continue;
      }

      if (filters.workHoursOnly && filters.timeSegmentation === "hourly") {
        if (
          moment(dataPointTime)
            .set(epoch)
            .isBefore(filters.workHours.start)
        ) {
          continue;
        }
        if (
          moment(dataPointTime)
            .set(epoch)
            .isAfter(filters.workHours.end)
        ) {
          continue;
        }
      }

      const timeAsString = dataPointTime.toDate().toString();

      let value;
      if (filters.metric === "licensesUsed") {
        value = _.random(0, LICENSES_USED_MAX_VALUE);
      } else {
        value = _.random(0, USAGE_LEVEL_MAX_VALUE);
      }

      dataPoints.push([timeAsString, value]);
    }

    console.log(
      JSON.stringify({
        chartData: dataPoints,
        totalLicenses: LICENSES_USED_MAX_VALUE
      })
    );

    //return [500, { message: "Internal server error" }];
    return [
      200,
      {
        chartData: dataPoints,
        totalLicenses: LICENSES_USED_MAX_VALUE
      }
    ];
  } catch (err) {
    return [500, { message: "Internal server error" }];
  }
});

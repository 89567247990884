import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";

const initialState = {
  issuesOverview: [],
  chartData: []
};

const slice = createSlice({
  name: "issuesOverview",
  initialState,
  reducers: {
    getCrashReportSummary(state, action) {
      const issuesOverview = action.payload;
      state.issuesOverview = issuesOverview;
    },
    getCrashChartData(state, action) {
      const chartData = action.payload;
      state.chartData = chartData;
    }
  }
});

export const reducer = slice.reducer;

export const getCrashReportSummary = () => async dispatch => {
  const response = await axios.get("/api/CrashReports/summary");

  dispatch(slice.actions.getCrashReportSummary(response.data));
};

export const getCrashChartData = () => async dispatch => {
  const response = await axios.get("/api/CrashReports/chart");

  dispatch(slice.actions.getCrashChartData(response.data));
};

export default slice;

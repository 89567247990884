import _ from "lodash";
import axios from "src/utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allLicenses: [],
  usedLicenses: [],
  isReleaseDialogOpen: false,
  selectedUsedLicenseIds: []
};

const slice = createSlice({
  name: "licenses",
  initialState,
  reducers: {
    getAllLicenses(state, action) {
      const allLicenses = action.payload;

      state.allLicenses = allLicenses;
    },
    getUsedLicenses(state, action) {
      const usedLicenses = action.payload;

      state.usedLicenses = usedLicenses;
    },
    selectUsedLicensesForRelease(state, action) {
      const licenseIds = action.payload;

      state.isReleaseDialogOpen = true;
      state.selectedUsedLicenseIds = licenseIds;
    },
    releaseUsedLicenses(state, action) {
      const licenseIds = action.payload;

      state.usedLicenses = _.reject(state.usedLicenses, license =>
        licenseIds.includes(license.usedLicenseId)
      );
    },
    closeDialog(state) {
      state.isReleaseDialogOpen = false;
      state.selectedUsedLicenseIds = null;
    }
  }
});

export const reducer = slice.reducer;

export const getAllLicenses = () => async dispatch => {
  const response = await axios.get("/api/licenses/all");

  dispatch(slice.actions.getAllLicenses(response.data));
};

export const getUsedLicenses = () => async dispatch => {
  const response = await axios.get("/api/licenses/used");

  dispatch(slice.actions.getUsedLicenses(response.data));
};

export const selectUsedLicensesForRelease = licenseIds => async dispatch => {
  dispatch(slice.actions.selectUsedLicensesForRelease({ licenseIds }));
};

export const releaseUsedLicenses = licenseIds => async dispatch => {
  await axios.post("/api/licenses/used/remove", {
    licenseIds
  });

  dispatch(slice.actions.releaseUsedLicenses({ licenseIds }));
};

export const closeDialog = () => dispatch => {
  dispatch(slice.actions.closeDialog());
};

export default slice;

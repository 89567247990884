import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "src/utils/axios";

const initialState = {
  notifications: [],
  isPopoverOpen: false
};

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    getNotifications(state, action) {
      const notifications = action.payload;

      state.notifications = notifications;
    },
    clearNotifications(state, action) {
      const notificationIds = action.payload;

      state.notifications = _.reject(state.notifications, notification =>
        notificationIds.includes(notification.id)
      );
      state.isPopoverOpen = false;
    },
    setPopoverOpen(state, action) {
      state.isPopoverOpen = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getNotifications = () => async dispatch => {
  // const response = await axios.get("/api/notifications");

  // dispatch(slice.actions.getNotifications(response.data));
};

export const clearNotifications = notificationIds => async dispatch => {
  await axios.post("/api/notifications/clear", {
    notificationIds
  });

  dispatch(slice.actions.clearNotifications({ notificationIds }));
};

export const setPopoverOpen = open => dispatch => {
  dispatch(slice.actions.setPopoverOpen(open));
};

export default slice;

import React from "react";
import { Avatar, makeStyles } from "@material-ui/core";
import seedRandom from "seedrandom";
import getContrastColor from "./getContrastColor";
import clsx from "clsx";

const generateNumberInRange = (seed, min, max) => {
  return Math.floor(seedRandom(seed)() * (max - min + 1) + min);
};

const useStyles = makeStyles(theme => ({
  avatar: {
    fontSize: "1rem"
  },
  popover: {
    width: 200
  }
}));

const UserAvatar = (
  { firstName, lastName, image, className, style, ...rest },
  ref
) => {
  const classes = useStyles();

  let avatarChars = firstName.charAt(0) + lastName.charAt(0);
  avatarChars = avatarChars.toUpperCase();

  const backgroundColors = [
    "#F94144",
    "#F3722C",
    "#F8961E",
    "#F9844A",
    "#F9C74F",
    "#90BE6D",
    "#43AA8B",
    "#4D908E",
    "#577590",
    "#277DA1"
  ];
  const backgroundColorIndex = generateNumberInRange(
    avatarChars,
    0,
    backgroundColors.length - 1
  );
  const backgroundColor = backgroundColors[backgroundColorIndex];

  const color = getContrastColor(backgroundColor);

  return (
    <Avatar
      src={image}
      alt={`${firstName} ${lastName}`}
      className={clsx(classes.avatar, className)}
      style={{ backgroundColor, color, ...style }}
      ref={ref}
      {...rest}
    >
      {!image && avatarChars}
    </Avatar>
  );
};

export default React.forwardRef(UserAvatar);

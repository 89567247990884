import { StylesProvider, ThemeProvider, jssPreset } from "@material-ui/core";
import routes, { renderRoutes } from "src/routes";

import { AuthProvider } from "src/contexts/JWTAuthContext";
import GlobalStyles from "src/components/GlobalStyles";
import GoogleAnalytics from "src/components/GoogleAnalytics";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { Router } from "react-router-dom";
import ScrollReset from "src/components/ScrollReset";
import { SnackbarProvider } from "notistack";
import { THEMES } from "src/constants";
import { create } from "jss";
import { createBrowserHistory } from "history";
import { hugeiconsLicense } from "@hugeicons/react-pro";
import { createTheme } from "src/theme";
import rtl from "jss-rtl";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
  hugeiconsLicense("150eafea8c7e2d8e99e4c1f89374d6e6RT00ODIzODY2OTc3MDAwLFM9cHJvLFY9MSxQPUd1bXJvYWQsU1Q9NTdBMTk4MEIsRVQ9NkY0ODNBNEQ=");
  
  const theme = createTheme({
    direction: "ltr",
    responsiveFontSizes: true,
    theme: THEMES.LIGHT
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider maxSnack={3}>
            <Router history={history}>
              <AuthProvider>
                <GlobalStyles />
                <ScrollReset />
                <GoogleAnalytics />
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;

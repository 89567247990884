import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "src/utils/axios";
import moment from "moment";

const initialState = {
  chartData: null,
  calendarData: null,
  totalLicenses: 0,
  startDate: moment().utc(),
  filters: {
    product: "all",
    range: "currentMonth",
    customRange: {
      start: moment()
        .subtract(1, "months")
        .toDate()
        .toString(),
      end: moment()
        .toDate()
        .toString(),
      excludeWeekends: false
    },
    metric: "licensesUsed"
  }
};

const slice = createSlice({
  name: "usageHeatMap",
  initialState,
  reducers: {
    getChartData(state, action) {
      const { chartData, calendarData, totalLicenses } = action.payload;
      state.chartData = chartData;
      state.calendarData = calendarData;
      state.totalLicenses = totalLicenses;
      const startDate =
        !calendarData ||
        !calendarData[0] ||
        !calendarData[0].Data ||
        !calendarData[0].Data[0]
          ? moment().utc()
          : moment(calendarData[0].Data[0]).utc();
      window.dispatchEvent(
        new CustomEvent("heatMapChartUpdated", {
          detail: { date: startDate }
        })
      );
    },
    getFilters(state, action) {
      const { filters } = action.payload;

      if (_.isEmpty(filters)) {
        return;
      }

      state.filters = filters;
    },
    setFilters(state, action) {
      const { filters } = action.payload;

      state.filters = filters;
    }
  }
});

export const reducer = slice.reducer;

export const getChartData = filters => async dispatch => {
  const response = await axios.post("/api/admin/usage/heatMap", {
    filters
  });

  dispatch(slice.actions.getChartData(response.data));
};

export const getFilters = () => async dispatch => {
  let filters = {};

  try {
    //filters = JSON.parse(localStorage.getItem("usageHeatMapFilters"));
  } finally {
    dispatch(slice.actions.getFilters({ filters }));
  }
};

export const setFilters = filters => async dispatch => {  
  //localStorage.setItem("usageHeatMapFilters", JSON.stringify(filters));

  dispatch(slice.actions.setFilters({ filters }));
};

export default slice;

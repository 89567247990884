import { Box, Button, Typography, makeStyles } from "@material-ui/core";

import React from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  trainingText: {
    margin: "10px 0"
  },
  addTrainingRequestButton: {
    width: "100%"
  }
}));

const RequestTrainingItem = () => {
  const classes = useStyles();

  return (
    <Box p={2}>
      <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
        <Typography variant="h6" color="textPrimary">
          Complimentary Training
        </Typography>
        <Typography
          className={classes.trainingText}
          variant="body2"
          color="textSecondary"
        >
          Request free training for your users to maximize your license usage
          and improve your engineering work production
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.addTrainingRequestButton}
          component={RouterLink}
          to="/app/training"
        >
          Request training
        </Button>
      </Box>
    </Box>
  );
};

export default RequestTrainingItem;

import React from "react";
import useAuth from "../hooks/useAuth";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if(user.isTempPassword){
    return <Redirect to="/app/changePassword" />;  
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;

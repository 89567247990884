/* eslint-disable no-use-before-define */

import {
  BarChartOutlined as BarChartIcon,
  BookOutlined as BookIcon,
  HomeOutlined as HomeIcon,
  LaptopOutlined as LaptopIcon,
  NotificationsOutlined as NotificationsIcon,
  SettingsOutlined as SettingIcon,
  TimelineOutlined as TimelineIcon
} from "@material-ui/icons";
import {
  Box,
  Button,
  Divider,
  Drawer, 
  Hidden,
  List,
  makeStyles
} from "@material-ui/core";
import React, { useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";

import NavItem from "./NavItem";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import RequestTrainingItem from "./RequestTrainingItem";
import { Link as RouterLink } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

const items = [
  {
    title: "Home",
    icon: HomeIcon,
    href: "/app/home"
  },
  {
    title: "Issues",
    icon: BookIcon,
    href: "/app/issues/all"
  },
  {
    title: "Stack Trace Deobfuscator",
    icon: LaptopIcon,
    href: "/app/stackTraceDeobfuscator"
  },
  // {
  //   title: "Usage Chart",
  //   icon: TimelineIcon,
  //   href: "/app/usage/chart"
  // },
  // {
  //   title: "Usage Heat Map",
  //   icon: BarChartIcon,
  //   href: "/app/usage/heatmap"
  // },
  // {
  //   title: "Licensing Alerts",
  //   icon: NotificationsIcon,
  //   href: "/app/alerts"
  // },
  {
    title: "Admin Center",
    icon: SettingIcon,
    adminOnly: true,
    items: [
      {
        title: "Manage Users",
        href: "/app/admin/users/manage"
      }]
  //     {
  //       title: "Manage User Groups",
  //       href: "/app/admin/groups/manage"
  //     },
  //     {
  //       title: "Welcome Emails",
  //       href: "/app/admin/emails/manage"
  //     },
  //     {
  //       title: "Custom User Fields",
  //       href: "/app/admin/customfields/manage"
  //     }
  //   ]
   }
];

function renderNavItems({ items, user, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, user, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, user, item, depth }) {
  const key = item.title + depth;

  if (item.adminOnly && !user.admin) {
    return acc;
  }

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          user,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)"
  },
  addLicensesButton: {
    width: "100%"
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          {renderNavItems({
            items,
            user,
            pathname: location.pathname
          })}
        </Box>
        <Divider />
        <Box p={2}>
          <Button
            className={classes.addLicensesButton}
            variant="contained"
            color="primary"
            component={RouterLink}
            to="/app/issues/viewErrorReport"
          >
            View Error Report
          </Button>
        </Box>
        {/* <Divider /> */}
        {/* <RequestTrainingItem /> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;

import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import SmallLogo from "src/components/SmallLogo";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Typography
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { THEMES } from "src/constants";
import Account from "./Account";
import Notifications from "./Notifications";

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: "none",
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  },
  header: {
    color: theme.palette.background.default,
    textDecoration: "none",
    "& div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "& h1": {
      [theme.breakpoints.down("md")]: {
        fontSize: "1.4rem"
      }
    },
    "& img": {
      [theme.breakpoints.down("md")]: {
        height: "1.4rem"
      },
      height: "1.6rem",
      marginRight: "8px"
    }
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <RouterLink to="/" className={classes.header}>
            <Box>
              <SmallLogo />
              <Typography variant="h1">License Manager</Typography>
            </Box>
          </RouterLink>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/" className={classes.header}>
            <Box>
              <SmallLogo />
              <Typography variant="h1">CivilGEO Issue Tracking Portal</Typography>
            </Box>
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {/* <Notifications /> */}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;

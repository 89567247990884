import { combineReducers } from "@reduxjs/toolkit";
import { reducer as customFieldsReducer } from "src/slices/admin/customFields";
import { reducer as groupsReducer } from "src/slices/admin/groups";
import { reducer as notificationReducer } from "src/slices/notification";
import { reducer as licenseOverviewReducer } from "src/slices/licenseOverview";
import { reducer as licenseAlertsReducer } from "src/slices/licenseAlerts";
import { reducer as licenseStatusesReducer } from "src/slices/licenseStatuses";
import { reducer as licensesReducer } from "src/slices/licenses";
import { reducer as issuesReducer } from "src/slices/issues";
import { reducer as savedDefaultsReducer } from "src/slices/savedDefaults";
import { reducer as serviceStatusReducer } from "src/slices/serviceStatus";
import { reducer as usageChartReducer } from "src/slices/usageChart";
import { reducer as usageHeatMapReducer } from "src/slices/usageHeatMap";
import { reducer as usersReducer } from "src/slices/admin/users";
import { reducer as welcomeEmailsReducer } from "src/slices/admin/welcomeEmails";
import {reducer as updateProfileReducer} from "src/slices/UpdateProfile";
import {reducer as issuesOverviewReducer} from "src/slices/issuesOverview";

const appReducer = combineReducers({
  customFields: customFieldsReducer,
  groups: groupsReducer,
  notifications: notificationReducer,
  licenseOverview: licenseOverviewReducer,
  licenseAlerts: licenseAlertsReducer,
  licenseStatuses: licenseStatusesReducer,
  licenses: licensesReducer,
  issues: issuesReducer,  
  savedDefaults: savedDefaultsReducer,
  serviceStatus: serviceStatusReducer,
  usageChart: usageChartReducer,
  usageHeatMap: usageHeatMapReducer,
  users: usersReducer,
  welcomeEmails: welcomeEmailsReducer,
  updateProfile:updateProfileReducer,
  issuesOverview: issuesOverviewReducer
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;

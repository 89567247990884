import _ from "lodash";
import axios from "src/utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  crashReportId: null,
  allIssues: [],
  pageNumber: 0,
  totalRowsCount: 0,
  rowsPerPage: 10,
  sortName: null,
  sortDirection: null,
  selectedIssue: null,
  selectedIndex: 0,
  attachments:[],
  similarIssues:[],
  clientDetails: null,
  ticketDetails: null,
  caseDetails: null,
  sessionDetails: [],
  usedLicenses: [],
  isReleaseDialogOpen: false,
  selectedUsedLicenseIds: [],
  stackTrace: ""
};

const slice = createSlice({
  name: "issues",
  initialState,
  reducers: {
    addCrashReport(state, action) {
      const crashReportId = action.payload;

      state.crashreportId = crashReportId;
    },

    getAllIssues(state, action) {
      const { crashReports, rowsCount, rowsPerPage} = action.payload;

      state.allIssues = crashReports;      
      state.totalRowsCount = rowsCount;
      state.rowsPerPage = rowsPerPage;
    },

    getSelectedIssue(state, action){
      const selectedIssue = action.payload;
      state.selectedIssue = selectedIssue;
    },

    setSelectedIndex(state, action) {
      const selectedIndex = action.payload;      
      state.selectedIndex = selectedIndex;
    },

    getAttachments(state, action) {
      const attachments = action.payload;      
      state.attachments = attachments;
    },

    getSimilarIssues(state, action) {
      const similarIssues = action.payload;      
      state.similarIssues = similarIssues;
    },

    getClientDetails(state, action) {
      const clientDetails = action.payload;      
      state.clientDetails = clientDetails;
    },

    getTicketDetails(state, action) {
      const ticketDetails = action.payload;      
      state.ticketDetails = ticketDetails;
    },

    getJiraDetails(state, action) {
      const caseDetails = action.payload;
      state.caseDetails = caseDetails;
    },

    getSessionDetails(state, action) {
      const sessionDetails = action.payload;
      state.sessionDetails = sessionDetails;
    },

    setFixedVersion(state, action) {
      const result = action.payload;
      state.selectedIssue.fixedVersion = result?.fixedVersion;
      state.selectedIssue.isFixed = result?.fixedVersion != "";
      state.selectedIssue.fixVersionUpdatedBy = result?.fixVersionUpdatedBy;
      state.selectedIssue.fixVersionUpdatedDate = result?.fixVersionUpdatedDate;
      state.selectedIssue.fixedDate = result?.fixVersionUpdatedDate;
    },
    
    markAsDuplicate(state, action) {
      const result = action.payload;
      state.similarIssues = _.map(state.similarIssues, _issue => {
        if (_issue.crashReportId === result.crashReportId) {
          return result;
        }

        return _issue;
      });
    },

    deobFuscateStackTrace(state, action) {
      const result = action.payload;
      state.stackTrace = result;
    },

    createUploadLink(state, action) {
      const result = action.payload;
      state.selectedIssue.shareFileLink = result?.shareFileModel.uploadUrl;
      state.selectedIssue.wantsToUploadProblematicData = result?.wantsToUploadProblematicData;
      state.selectedIssue.shareFileDownloadLink = result?.shareFileModel.downloadUrl;
      state.selectedIssue.shareFileFolder = result?.shareFileModel.folderPath;
    }
  }
});

export const reducer = slice.reducer;

export const addCrashReport = (crashReportFile) => async dispatch => {
  const response = await axios.post("/api/CrashReports/AddCrashReport", {
    crashReportFile
  });

  return response.data;

  //dispatch(slice.actions.addCrashReport(response.data));
};


export const getAllIssues = (pageNumber, rowsPerPage, sortName, sortDirection, productName, isFixed, isDuplicate, isReported, searchText) => async dispatch => {
  if(sortName === null || sortName === undefined || sortName === 'null')
  {
    sortName ="";
  }
  if(sortDirection === null || sortDirection === undefined || sortDirection === 'null')
  {
    sortDirection ="";
  }
  if(searchText === null || searchText === undefined || searchText === 'null')
  {
    searchText ="";
  }

  const response = await axios.get(
    "/api/CrashReports?PageNumber=" +
      pageNumber +
      "&rowsPerPage=" +
      rowsPerPage +
      "&sortName=" +
      sortName +
      "&sortDirection=" +
      sortDirection +
      "&productName=" +
      productName +
      "&isFixed=" +
      (isFixed && isFixed)  +
      "&isDuplicate=" +
      (isDuplicate && isDuplicate) +
      "&isReported=" +
      (isReported && isReported) +
      "&searchText=" +
      searchText
  );

  dispatch(slice.actions.getAllIssues(response.data));
};



export const getSelectedIssue = (crashReportId) => async dispatch => {
  if(crashReportId === '')
  {
    dispatch(slice.actions.getSelectedIssue(null));
    //return null;
  }
  const response = await axios.get("/api/CrashReports/getCrashReportById?crashReportId=" + crashReportId);


  //return response.data;
  dispatch(slice.actions.getSelectedIssue(response.data));
};

export const getAttachments = (crashReportId) => async dispatch => {
  if(crashReportId === '')
  {
    dispatch(slice.actions.getAttachments(null));
  }

  const response = await axios.get("/api/CrashReports/GetAttachments?crashReportId=" + crashReportId);

  dispatch(slice.actions.getAttachments(response.data));
};


export const getAttachment = (fileId) => async dispatch => {
  if(fileId === '')
  {
    return null;
  }

  const response = await axios.get("/api/CrashReports/GetAttachment?fileId=" + fileId);

  //dispatch(slice.actions.getAttachment(response.data));
  return response.data;
};

export const getSimilarIssues = (crashReportId) => async dispatch => {
  if(crashReportId === '')
  {
    dispatch(slice.actions.getSimilarIssues(null));
    return;
  }
  const response = await axios.get("/api/CrashReports/GetSimilarIssues?crashReportId=" + crashReportId);

  dispatch(slice.actions.getSimilarIssues(response.data));
};

export const getClientDetails = (serialNumber) => async dispatch => {
  if(serialNumber === '')
  {
    dispatch(slice.actions.getClientDetails(null));
    return;
  }

  const response = await axios.get("/api/CrashReports/getClientDetails?serialNumber=" + serialNumber);

  dispatch(slice.actions.getClientDetails(response.data));
};

export const getTicketDetails = (ticketId) => async dispatch => {
  if(ticketId === '')
  {
    dispatch(slice.actions.getTicketDetails(null));
    return;
  }

  const response = await axios.get("/api/CrashReports/getTicketDetails?ticketId=" + ticketId);

  dispatch(slice.actions.getTicketDetails(response.data));
};

export const getJiraDetails = (caseNo) => async dispatch => {
  if(caseNo === '')
  {
    dispatch(slice.actions.getJiraDetails(null));
    return;
  }

  const response = await axios.get("/api/CrashReports/getJiraDetails?caseNo=" + caseNo);
  dispatch(slice.actions.getJiraDetails(response.data));
};

export const getSessionDetails = (sessionId) => async dispatch => {
  if(sessionId === '')
  {
    dispatch(slice.actions.getSessionDetails(null));
    return;
  }

  const response = await axios.get("/api/CrashReports/getSessionDetails?sessionId=" + sessionId);
  dispatch(slice.actions.getSessionDetails(response.data));
};

export const setFixedVersion = (crashReportId, fixedVersion, isSendEmail) => async dispatch => {
  if(fixedVersion ==='' || crashReportId === '')
  { 
    dispatch(slice.actions.setFixedVersion(response.data));
    return;
  }

  const response = await axios.post("/api/CrashReports/MarkAsFixed", {
    crashReportId,
    fixedVersion,
    isSendEmail
  });

  dispatch(slice.actions.setFixedVersion(response.data));
};

export const markAsDuplicate = (crashReportId, duplicateCrashReportId) => async dispatch => {
  if(duplicateCrashReportId ==='' || crashReportId === '')
  { 
    dispatch(slice.actions.markAsDuplicate(response.data));
    return;
  }

  const response = await axios.post("/api/CrashReports/MarkAsDuplicate", {
    crashReportId,
    duplicateCrashReportId
  });

  console.log(response);

  dispatch(slice.actions.markAsDuplicate(response.data));
};

export const deobFuscateStackTrace = (productName, productVersion, obString) => async dispatch => {
  if(productName ==='' || productVersion === '' || obString ==='')
  { 
    dispatch(slice.actions.deobFuscateStackTrace(response.data));
    return;
  }

  const response = await axios.post("/api/CrashReports/deobfuscateStackTrace", {
    productName,
    productVersion,
    obString
  });
  console.log(response);

  dispatch(slice.actions.deobFuscateStackTrace(response.data));
};

export const createUploadLink = (crashReportId) => async dispatch => {
  console.log(crashReportId);
  if(crashReportId === '')
  { 
    dispatch(slice.actions.createUploadLink(response.data));
    return;
  }

  const response = await axios.post("/api/CrashReports/CreateUploadLink?crashReportId="+crashReportId);
  console.log(response);
  dispatch(slice.actions.createUploadLink(response.data));
};

export const createFreshdeskTicket = (crashReportId) => async dispatch => {
  console.log(crashReportId);
  if(crashReportId === '')
  { 
    dispatch(slice.actions.getTicketDetails(response.data));
    return;
  }

  const response = await axios.post("/api/CrashReports/CreateFreshdeskTicket?crashReportId="+crashReportId);
  console.log(response);
  dispatch(slice.actions.getTicketDetails(response.data));
};

export const closeDialog = () => dispatch => {
  dispatch(slice.actions.closeDialog());
};

export default slice;

import { Buffer } from "buffer";
import bytesToSize from "./bytesToSize";

const getBytesFromBase64 = base64 => {
  if (base64) {
    var bytes = Buffer.from(base64, "base64");
    return bytes;
  } else {
    return null;
  }
};

const base64ToImage = imageData => {
  if (imageData) {
    return "data:image/png;base64," + imageData;
  } else {
    return null;
  }
};

const getTextFromBase64 = base64 => {
  var array = getBytesFromBase64(base64);
  if (array) {
    var result = new TextDecoder().decode(array);
    return result;
  } else {
    return null;
  }
};

const getFileSizeFromBase64 = base64 => {
  var bytes = getBytesFromBase64(base64);
  if (bytes) {
    var size = bytesToSize(bytes.length);
    return size;
  } else {
    return null;
  }
};

export { getBytesFromBase64, base64ToImage, getTextFromBase64, getFileSizeFromBase64 };
